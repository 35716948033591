<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <form>
      <div class="row">
        <div class="col-lg-12">
          <div>
            <router-link :to="'/doctors'" class="btn btn-success mb-3">
              <i class="mdi mdi-arrow-bottom-left-thick me-1"></i> Back
            </router-link>
          </div>
          <div id="header-accordion" class="custom-accordion">
            <!-- Page Genel Ayarlar -->
            <div class="card">
              <div class="p-4 border-top">
                <div class="row">
                  <div
                    class="col-lg-6 border-col"
                    v-for="item in 10"
                    :key="item"
                  >
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="namePage">Image {{ item }}</label> <br />
                        <img
                          :src="formData['img' + item]"
                          alt=""
                          class="img-fluid"
                          width="150"
                          height="150"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="namePage">Image <strong>*</strong></label>
                        <input
                          type="file"
                          class="form-control"
                          @change="fileChange('img' + item, $event)"
                          required
                        />
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="namePage"
                          >Image Sub Text <strong>*</strong></label
                        >
                        <input
                          v-model="formData['subText' + item]"
                          type="text"
                          class="form-control"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Page Genel Ayarlar -->
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col text-end ms-1">
          <button
            type="button"
            class="btn btn-danger"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-times me-1"></i> Cancel
          </button>
          <button
            type="submit"
            @click="save()"
            class="btn btn-success ms-1"
            :disabled="createSend == 1 ? true : false"
          >
            <i class="uil uil-file-alt me-1"></i> Save
          </button>
        </div>
        <!-- end col -->
      </div>
    </form>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { API } from "@/state/API";
import { POPUP } from "@/state/popup";

export default {
  page: {
    title: "Doctors Photo",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Doctors Photo",
      items: [
        {
          text: "Doctors Photo",
        },
        {
          text: "Create",
          active: true,
        },
      ],
      createSend: 0,
      formData: {
        img1: null,
        img2: null,
        img3: null,
        img4: null,
        img5: null,
        img6: null,
        img7: null,
        img8: null,
        img9: null,
        img10: null,
        subText1: "",
        subText2: "",
        subText3: "",
        subText4: "",
        subText5: "",
        subText6: "",
        subText7: "",
        subText8: "",
        subText9: "",
        subText10: "",
      },
      value1: null,
      options: [],
      langs: [],
    };
  },
  middleware: "authentication",
  mounted() {
    this.FindLp();
  },
  methods: {
    FindLp() {
      API.Get(API.Doctors.Photo.UpdateList + this.$route.params.id)
        .then((response) => {
          if (response.data.status == "success") {
            console.log(response.data.order);
            //order foreach
            response.data.order.forEach((element, index) => {
              this.formData["img" + (index + 1)] = element["img" + (index + 1)];
              this.formData["subText" + (index + 1)] =
                element["subText" + (index + 1)];
              console.log(this.formData["subText" + (index + 1)]);
            });

            console.log(this.formData);
          }
        })
        .catch((error) => {
          console.error("Error while fetching data:", error);
        });
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async save() {
      this.createSend = 1;
      var data;
      const response = await API.Post(
        API.Doctors.Photo.Create + this.$route.params.id,
        this.convertToFormData()
      );
      console.log(response.data);
      if (response.data.status == "success") {
        this.createSend = 0;

        data = "Your work has update";
        POPUP.popupClick(data);
        this.FindLp();
      } else {
        this.createSend = 0;
        data = "Error";
        POPUP.popupClickNot(data);
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
  },
};
</script>

<style scoped>
strong {
  color: rgba(239, 4, 4, 0.39);
}
.border-col {
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 10px;
}
</style>
